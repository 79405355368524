var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "arrivalExceptionReport-page" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "arrivalExceptionReport-search-form",
          attrs: {
            model: _vm.form,
            inline: true,
            "label-position": "right",
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "车辆所属公司：" } },
            [
              _c("companySelectForSearch", {
                ref: "companySelectForSearch",
                attrs: { multiple: false, searchable: true },
                on: { getValue: _vm.getCompanyIdFun }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "任务排班所属公司：" } },
            [
              _c("companySelectForSearch", {
                ref: "companySelectForSearch",
                attrs: { multiple: false, searchable: true },
                on: { getValue: _vm.getTaskLineCompanyIdFun }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "查询时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                model: {
                  value: _vm.form.datetimerange,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "datetimerange", $$v)
                  },
                  expression: "form.datetimerange"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "业务方向：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.form.moveType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "moveType", $$v)
                    },
                    expression: "form.moveType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "上行", value: 1 } }),
                  _c("el-option", { attrs: { label: "下行", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "站点：" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.remoteMethod
                  },
                  model: {
                    value: _vm.form.stationId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "stationId", $$v)
                    },
                    expression: "form.stationId"
                  }
                },
                _vm._l(_vm.stationIdList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.name }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.onSearch()
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, width: "100%", height: "66%" } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: { prop: "lineName", label: "所属车队" }
          }),
          _c("el-table-column", { attrs: { prop: "cph", label: "车牌号" } }),
          _c("el-table-column", {
            attrs: { prop: "taskLineName", label: "任务线路名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "arriveTime", label: "到站时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "moveType", label: "运行方向" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        scope.row.moveType == 1
                          ? "上行"
                          : scope.row.moveType == 2
                          ? "下行"
                          : ""
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "stationName", label: "站点名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "distance", label: "报站间距（m）" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small", sort: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.viewFun(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
          "page-size": _vm.form.pageSize,
          "current-page": _vm.form.currentPage
        },
        on: {
          "size-change": _vm.onSizeChange,
          "current-change": _vm.onCurrentChange
        }
      }),
      _vm.dialogTableVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogTableVisible,
                "close-on-click-modal": false,
                title: "查看详情",
                width: "75%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogTableVisible = $event
                }
              }
            },
            [
              _c("div", { staticClass: "arrivalExceptionReport-dialog" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          "label-width": "130px",
                          model: _vm.detailsInfo,
                          "label-position": "left"
                        }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "所属车队" } }, [
                          _vm._v(_vm._s(_vm.detailsInfo.lineName) + " ")
                        ]),
                        _c("el-form-item", { attrs: { label: "车牌号" } }, [
                          _vm._v(_vm._s(_vm.detailsInfo.cph) + " ")
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "任务线路名称" } },
                          [_vm._v(_vm._s(_vm.detailsInfo.taskLineName) + " ")]
                        ),
                        _c("el-form-item", { attrs: { label: "到站时间" } }, [
                          _vm._v(_vm._s(_vm.detailsInfo.arriveTime) + " ")
                        ]),
                        _c("el-form-item", { attrs: { label: "运行方向" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.detailsInfo.moveType == 1
                                ? "上行"
                                : _vm.detailsInfo.moveType == 2
                                ? "下行"
                                : ""
                            ) + " "
                          )
                        ]),
                        _c("el-form-item", { attrs: { label: "站点名称" } }, [
                          _vm._v(_vm._s(_vm.detailsInfo.stationName) + " ")
                        ]),
                        _c("el-form-item", { attrs: { label: "报站经纬度" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.detailsInfo.longitude +
                                "," +
                                _vm.detailsInfo.latitude
                            ) + " "
                          )
                        ]),
                        _c("el-form-item", { attrs: { label: "站点经纬度" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.detailsInfo.stationLongitude +
                                "," +
                                _vm.detailsInfo.stationLatitude
                            ) + " "
                          )
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "报站间距（m）" } },
                          [_vm._v(_vm._s(_vm.detailsInfo.distance) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "right" }, [
                  _c("div", { attrs: { id: "container" } })
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }