<template>
  <!-- 到站异常报表 -->
  <div class="arrivalExceptionReport-page">
    <el-form
      ref="form"
      :model="form"
      :inline="true"
      class="arrivalExceptionReport-search-form"
      label-position="right"
      label-width="130px"
    >
      <el-form-item label="车辆所属公司：">
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          @getValue="getCompanyIdFun"
        />
      </el-form-item>
      <el-form-item label="任务排班所属公司：">
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          @getValue="getTaskLineCompanyIdFun"
        />
      </el-form-item>
      <el-form-item label="查询时间：">
        <el-date-picker
          v-model="form.datetimerange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="业务方向：">
        <el-select v-model="form.moveType" clearable>
          <el-option label="上行" :value="1"></el-option>
          <el-option label="下行" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="站点：">
        <el-select
          v-model="form.stationId"
          clearable
          filterable
          remote
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in stationIdList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSearch()"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="tableData" width="100%" height="66%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="lineName" label="所属车队"></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column
        prop="taskLineName"
        label="任务线路名称"
      ></el-table-column>
      <el-table-column prop="arriveTime" label="到站时间"></el-table-column>
      <el-table-column prop="moveType" label="运行方向">
        <template slot-scope="scope">{{
          scope.row.moveType == 1
            ? "上行"
            : scope.row.moveType == 2
            ? "下行"
            : ""
        }}</template>
      </el-table-column>
      <el-table-column prop="stationName" label="站点名称"></el-table-column>
      <el-table-column prop="distance" label="报站间距（m）"></el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="viewFun(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :page-size="form.pageSize"
      :current-page="form.currentPage"
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    ></el-pagination>
    <el-dialog
      v-if="dialogTableVisible"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      title="查看详情"
      width="75%"
    >
      <div class="arrivalExceptionReport-dialog">
        <div class="left">
          <el-form
            label-width="130px"
            :model="detailsInfo"
            label-position="left"
          >
            <el-form-item label="所属车队"
              >{{ detailsInfo.lineName }}
            </el-form-item>
            <el-form-item label="车牌号">{{ detailsInfo.cph }} </el-form-item>
            <el-form-item label="任务线路名称"
              >{{ detailsInfo.taskLineName }}
            </el-form-item>
            <el-form-item label="到站时间"
              >{{ detailsInfo.arriveTime }}
            </el-form-item>
            <el-form-item label="运行方向"
              >{{
                detailsInfo.moveType == 1
                  ? "上行"
                  : detailsInfo.moveType == 2
                  ? "下行"
                  : ""
              }}
            </el-form-item>
            <el-form-item label="站点名称"
              >{{ detailsInfo.stationName }}
            </el-form-item>
            <el-form-item label="报站经纬度"
              >{{ detailsInfo.longitude + "," + detailsInfo.latitude }}
            </el-form-item>
            <el-form-item label="站点经纬度"
              >{{
                detailsInfo.stationLongitude + "," + detailsInfo.stationLatitude
              }}
            </el-form-item>
            <el-form-item label="报站间距（m）"
              >{{ detailsInfo.distance }}
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <div id="container"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import companySelectForSearch from "@/components/treeSelect/companySelectForSearchNew.vue";
import {
  abnormalArriveQueryPageList,
  stationQueryByName,
  abnormalArriveQueryById
} from "@/api/lib/bus-api.js";
import AMap from "AMap";
export default {
  name: "",
  components: { companySelectForSearch },
  props: {},
  data() {
    return {
      total: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        companyId: null,
        taskLineCompanyId: null,
        datetimerange: null,
        arriveBeginTime: "",
        arriveEndTime: "",
        moveType: null,
        stationId: null
      },
      stationIdList: [],
      dialogTableVisible: false,
      detailsInfo: {},
      map: null
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate() - 1;
    this.form.datetimerange = [
      `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      } 00:00:00`,
      `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      } 23:59:59`
    ];
    this.form.arriveBeginTime = this.form.datetimerange[0];
    this.form.arriveEndTime = this.form.datetimerange[1];
    this.getAbnormalArriveQueryPageList();
  },
  methods: {
    viewFun(row) {
      abnormalArriveQueryById({ id: row.id }).then(res => {
        if (res.code === 1000) {
          this.detailsInfo = res.data;
          this.dialogTableVisible = true;
          this.$nextTick(() => {
            this.loadmap();
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    /** 加载地图和相关组件 */
    loadmap() {
      let map = new AMap.Map("container", {
        mapStyle: "amap://styles/fresh",
        zoom: 11,
        resizeEnable: true,
        expandZoomRange: true,
        features: ["bg", "road", "building", "point"]
      });
      var busMarker = new AMap.Marker({
        position: [this.detailsInfo.longitude, this.detailsInfo.latitude],
        icon: new AMap.Icon({
          image: require("@/assets/images/busIcon/bus_map_offline.png"),
          imageSize: new AMap.Size(30, 30)
        })
      });
      var stationMarker = new AMap.Marker({
        position: [
          this.detailsInfo.stationLongitude,
          this.detailsInfo.stationLatitude
        ]
      });
      busMarker.setMap(map);
      stationMarker.setMap(map);
      map.setFitView();
      this.map = map;
    },
    getCompanyIdFun(val) {
      this.form.companyId = val[0];
    },
    getTaskLineCompanyIdFun(val) {
      this.form.taskLineCompanyId = val[0];
    },
    // 点击查询
    onSearch() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getAbnormalArriveQueryPageList();
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getAbnormalArriveQueryPageList();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.getAbnormalArriveQueryPageList();
    },
    getAbnormalArriveQueryPageList() {
      abnormalArriveQueryPageList(this.form).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.warning(res.msg);
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        setTimeout(() => {
          stationQueryByName({ stationName: query }).then(res => {
            if (res.code === 1000) {
              this.stationIdList = res.data;
            } else {
              this.stationIdList = [];
            }
          });
        }, 200);
      } else {
        this.stationIdList = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.arrivalExceptionReport-page {
  height: 100%;
  .arrivalExceptionReport-search-form {
    .el-form-item {
      margin-right: 20px;
    }
  }
  .arrivalExceptionReport-dialog {
    display: flex;
    .left {
      width: calc(40% - 20px - 20px);
    }
    .right {
      width: 60%;
      height: 45vh;
      padding: 0 20px 20px;
      #container {
        width: 100%;
        height: calc(62vh - 38px);
      }
    }
  }
}
</style>
